import React from "react"
import styles from "./styles.module.css"

class paymentStructure extends React.Component {
  render() {
    const {progress} = this.props;
   
    return (
      <>
        <div className={[styles.process_btn, "bg-white", "rounded-top","d-none","d-md-block"].join(" ")}>
					<ul className={["nav", "nav-tabs", styles.paybtn_area, "nav-justified"].join(" ")}>
					  <li className="nav-item">
					    <span className={["nav-link", "active"].join(" ")}>Select Product</span>
					  </li>
					  <li className="nav-item">
					    <span className={["nav-link", progress > 30 ? "active" : ""].join(" ")} href="#">Enter Your Information</span>
					  </li>
					  <li className="nav-item">
					    <span className={["nav-link", progress > 60 ? "active" : ""].join(" ")} href="#">Make Payment</span>
					  </li>
					</ul>
          <div className="progress">
					  <div className="progress-bar" role="progressbar" style={{"width": `${progress}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
				</div>
      </>
    )
  }
}

export default paymentStructure