import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { createPreliminary, getOrderProcessingToken, accountCreatePreliminary, getUrlEmailAddr, getUrlReferralCode } from "../../services/orderProcessing";
import { getAccountPrefix } from "../../common/gFunctions";
import { getToken, getUser } from "../../services/auth";
import styles from "./styles.module.css";
import PaymentStructure from "../../shared/paymentStructure";
import PlanInformation from "../../shared/planInformation";
import { IdentityFieldsForm } from "../identityFieldsForm";
import Helmet from "react-helmet"
import { isValidationError, identityValidationFields } from "../../common/validation";
import ProceedButton from "../proceedButton";
import AlertModal from "../../shared/alertModal";

class CustomerInformationComponent extends React.Component {
  constructor(props) {
      super(props);
      const user = getUser();
      const token = user && user.access_token;
      const isTemp = user && user.id ==='TEMP';
      var codeFromUrl = getUrlReferralCode();
      var emailFromUrl = getUrlEmailAddr();

      var isEmailDisabledDueToCodeUrl = (codeFromUrl && emailFromUrl) != null;

      this.state = {
            isEmailDisabledDueToCodeUrl,
            OrderNew: {
                CustomerDetails: {
                    FirstName: "",
                    LastName: "",
                    EmailAddr: this.props.selectedEmail || "",
                    CompanyName: "",
                    TelCountryCode: "91",
                    TelNum: "",
                    CellCountryCode: "91",
                    CellNum: "",
                    GSTIN: "",
                    Address1: "",
                    Address2: "",
                    City: "",
                    StateCode: -999,
                    Country: "India",
                    ZipCode: "",
                    InvoiceCompanyName: false
                },
                BillingDetails: {
                    FirstName: "",
                    LastName: "",
                    EmailAddr: "",
                    CompanyName: "",
                    TelCountryCode: "91",
                    TelNum: "",
                    CellCountryCode: "91",
                    CellNum: "",
                    GSTIN: "",
                    Address1: "",
                    Address2: "",
                    City: "",
                    StateCode: -999,
                    Country: "India",
                    ZipCode: "",
                    InvoiceCompanyName: false
                },
                IsBillingSame: true
            },
            isTemp,
            dirty: false,
            proceedLoading: false,
            duplicateEmail: '',
            incorrectEmailMessage: '',
            isModalOpen: {
                duplicateEmail: false,
                incorrectEmail: false
            },
            token
      };

      if (this.props.orderPlacementDetails) {
          var orderPlacementDetails = this.props.orderPlacementDetails;
          this.state = {
              ...this.state,
              OrderNew: {
                  CustomerDetails: {
                      ...orderPlacementDetails.CustomerDetails,
                      TelCountryCode: orderPlacementDetails.CustomerDetails.TelCountryCode || "91",
                      CellCountryCode: orderPlacementDetails.CustomerDetails.CellCountryCode || "91",
                      StateCode: orderPlacementDetails.CustomerDetails.StateCode || -999,
                      Country: orderPlacementDetails.CustomerDetails.Country || "India",
                  },
                  BillingDetails: {
                    ...orderPlacementDetails.BillingDetails,
                    TelCountryCode: orderPlacementDetails.BillingDetails.TelCountryCode || "91",
                    CellCountryCode: orderPlacementDetails.BillingDetails.CellCountryCode || "91",
                    StateCode: orderPlacementDetails.BillingDetails.StateCode || -999,
                    Country: orderPlacementDetails.BillingDetails.Country || "India",
                },
                  IsBillingSame: orderPlacementDetails.IsBillingSame
              }
          }
      }

      if (this.props.selectedProduct) {
        var selectedProduct = this.props.selectedProduct;
        this.state = {
          ...this.state,
          OrderNew: selectedProduct.OrderNew || this.state.OrderNew
        }
      }

  }

  componentDidMount() {
    this.props.resetOrderPending();
    window.document.body.classList.add('show-recaptcha');
    if (!this.props.priceDetails) {
        navigate(getAccountPrefix(this.state.token)+'/order/product');
    }
  }

  componentWillUnmount() {
    window.document.body.classList.remove('show-recaptcha')
  }

  handleInputChange = (event, type) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
     
    if (!type) {
        var BillingDetails = this.state.OrderNew.BillingDetails;

        if (target.name === 'IsBillingSame') {
            BillingDetails = value ? this.state.OrderNew.CustomerDetails : {
                FirstName: "",
                LastName: "",
                EmailAddr: "",
                CompanyName: "",
                TelCountryCode: "91",
                TelNum: "",
                CellCountryCode: "91",
                CellNum: "",
                GSTIN: "",
                Address1: "",
                Address2: "",
                City: "",
                StateCode: -999,
                Country: "India",
                ZipCode: "",
                InvoiceCompanyName: false
            }
        }

        this.setState({
            OrderNew: {
                ...this.state.OrderNew,
                BillingDetails,
                [name]: value
            }
        });
    } else {
        var StateCode = this.state.OrderNew[type].StateCode;
        var Country = this.state.OrderNew[type].Country;

        if (target.name === 'StateCode') {
            Country = value===-1 ? Country : "India";
            StateCode = value;
        } else if (target.name === 'Country') {
            Country = value;
            StateCode = value==="India" ? StateCode : -1;
        }

        this.setState({
            OrderNew: {
                ...this.state.OrderNew,
                [type]: {
                    ...this.state.OrderNew[type],
                    [name]: value,
                    StateCode,
                    Country
                }
            }
        });
    }
  }

  onEditClick = () => {
    const priceDetails = this.props.priceDetails;
    const path = (priceDetails.OrderType === 2 || this.props.priceDetails.OrderType === 3) ? '/account/order/upgrade' : getAccountPrefix(this.state.token) + '/order/product';
    navigate(path);
  }

  incorrectEmailError = (message) => {
    var email = this.state.OrderNew.CustomerDetails.EmailAddr;

    this.setState({
        OrderNew: {
            ...this.state.OrderNew,
            CustomerDetails: {
                ...this.state.OrderNew.CustomerDetails,
                EmailAddr: ""
            }
        },
        duplicateEmail: email,
        incorrectEmailMessage: message.Message,
        isModalOpen: {
            incorrectEmail: true
        },
        proceedLoading: false
    });
  }

  duplicateEmailError = () => {
      var email = this.state.OrderNew.CustomerDetails.EmailAddr;

      this.setState({
          OrderNew: {
              ...this.state.OrderNew,
              CustomerDetails: {
                  ...this.state.OrderNew.CustomerDetails,
                  EmailAddr: ""
              }
          },
          duplicateEmail: email,
          isModalOpen: {
              duplicateEmail: true
          },
          proceedLoading: false
      });
  }

  goToBuyRenew = () => {
      navigate('/buy'+(this.state.duplicateEmail ? '?email=' + encodeURIComponent(this.state.duplicateEmail) : ''));
  }

  proceed = () => {

    const customerRequiredError = isValidationError(this.state.OrderNew.CustomerDetails, identityValidationFields);
    const billingRequiredError = !this.state.OrderNew.IsBillingSame && isValidationError(this.state.OrderNew.BillingDetails, identityValidationFields);

    if(customerRequiredError || billingRequiredError) {
        this.setState({dirty: true});
    }

    else {
        this.setState({proceedLoading: true});

        this.props.setSelectedProduct({
            ...this.props.selectedProduct,
            OrderNew: this.state.OrderNew
        })

        const accountToken = getToken();

        if (accountToken !== this.state.token) {
            navigate('/login');
        } else {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY', {action: 'createpreliminary'}).then((token) => {
                    var request = accountToken && !this.state.isTemp ? accountCreatePreliminary(this.state.OrderNew,this.props.priceDetails,accountToken,token) : createPreliminary(this.state.OrderNew,this.props.priceDetails,token);
                    return request.then(({error,response}) => {
                        if (error.status>=400) {
                            if (error.status === 403) {
                                this.duplicateEmailError();
                            } else if (error.status == 406) {
                                this.incorrectEmailError(error.stack);
                            }
                        } else {
                            this.props.setOrderConfirm(response);
                            return getOrderProcessingToken(response.EmailAddr,response.TempConfirmationKey)  
                                .then(({error,response}) => {
                                    if (error.status>=400) {
                                    } else {
                                        this.props.setOrderProcessingToken(response);
                                        navigate(getAccountPrefix(accountToken)+'/order/payment');
                                    }
                                })              
                        }
                    })
                });
            })
        }
    }
  }

  toggleModal = (type) => {
    this.setState(
        {
            isModalOpen: {
                ...this.state.isModalOpen,
                [type]: !this.state.isModalOpen[type]
            }
        }
    )
  }

  toggleDuplicateEmailModal = () => {
    this.toggleModal('duplicateEmail');
  }

  toggleIncorrectEmailModal = () => {
    this.toggleModal('incorrectEmail');
  }

  render() {
    const priceDetails = this.props.priceDetails;

    return (
        <>
        <Helmet>
            <script id="grecaptcha" src="https://www.google.com/recaptcha/api.js?render=6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY"></script>
        </Helmet>
        <section className={[styles.paymentFTU,"show-recaptcha"].join(" ")}>
            <AlertModal isOpen={this.state.isModalOpen.duplicateEmail} toggle={this.toggleDuplicateEmailModal} headerText={'Email already exists'} bodyText={["There is an existing MProfit account associated with ",<a className={"blue-link"} href={`mailto:${this.state.duplicateEmail}`}>{this.state.duplicateEmail}</a>, `. Please login to this account or enter another email to continue.`]} option1={{text:'Enter new Email',onClick:this.toggleDuplicateEmailModal}} option2={{text:'Go to Login Page',onClick:this.goToBuyRenew}}/>
            <AlertModal isOpen={this.state.isModalOpen.incorrectEmail} toggle={this.toggleIncorrectEmailModal} headerText={'Email does not match code'} bodyText={[this.state.incorrectEmailMessage]} option2={{text:'Change Email',onClick:this.toggleIncorrectEmailModal}}/>
            <div className={["container", styles.pay_container].join(" ")}>
                <div className="row"></div>
                    <PaymentStructure progress={60}/>
                    <div className={["tab-content", styles.payContent, "text-center", "w-100"].join(" ")}>
                    { priceDetails &&
                        <div className={["tab-pane", "shipping_information", "active"].join(" ")} id="shipping_information">
                            <h2>You’re almost there</h2>
                            {   
                                this.props.orderPlacementDetails && (priceDetails.OrderType === 2 || priceDetails.OrderType === 3) &&
                                <PlanInformation existingPlan={this.props.orderPlacementDetails.Plan}/>
                            }
                            <PlanInformation priceDetails={priceDetails} onEditClick={this.onEditClick}/>
                            <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5", "p-4", "mb-3"].join(" ")}>
                                <div className={[styles.section_title, "mb-5"].join(" ")}>
                                    <h3>Personal Information</h3>
                                </div>
                                <IdentityFieldsForm sectionValue={this.state.OrderNew} sectionName={'CustomerDetails'} handleInputChange={this.handleInputChange} emailDisabled={this.state.token || this.state.isEmailDisabledDueToCodeUrl} dirty={this.state.dirty} />
                            </div>
                            <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5", "p-4"].join(" ")}>
                                
                                <div className="row">
                                    <div className={["form-group", "col-md-12"].join(" ")}>
                                        <label>Billing Information</label>
                                        <div className={["custom-control", "custom-checkbox"].join(" ")}>    
                                            <input className={["custom-input", "mr-2"].join(" ")} id="IsBillingSame"  name="IsBillingSame" type="checkbox" checked={this.state.OrderNew.IsBillingSame} onChange={this.handleInputChange} />
                                            <label className={styles.customLabel} htmlFor="IsBillingSame">Same as Personal Information</label>
                                        </div>
                                    </div>
                                </div>
                               { !this.state.OrderNew.IsBillingSame &&
                                <IdentityFieldsForm sectionValue={this.state.OrderNew} sectionName={'BillingDetails'} handleInputChange={this.handleInputChange} dirty={this.state.dirty}/>
                            }
                                <div className="row">
                                    <div className={["form-group", "col-md-12", "mt-4", "mb-0"].join(" ")}>
                                        {
                                            this.state.dirty &&
                                            <div className={styles.validationErrorText}>There are one or more errors in the fields above. Please correct the details to proceed.</div>
                                        }
                                        <ProceedButton disabled={false} onClick={this.proceed} loading={this.state.proceedLoading}/>
                                        <a onClick={this.onEditClick} className={["btn", "light_btn", "btn-block"].join(" ")}> <i className={["fa", "fa-angle-left", "mr-1"].join(" ")} aria-hidden="true"></i>Back </a>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        selectedProduct: state.order.SelectedProduct,
        priceDetails: state.order.PriceDetails,
        orderPlacementDetails: state.order.OrderPlacementDetails,
        selectedEmail: state.order.SelectedEmail
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        setSelectedProduct: (selectedProduct) => dispatch({type: 'SET_SELECTED_PRODUCT', data: selectedProduct}),
        setOrderConfirm: (orderConfirm) => dispatch({ type: `SET_ORDER_CONFIRM`, data: orderConfirm }),
        setOrderProcessingToken: (orderProcessingToken) => dispatch({ type: `SET_ORDER_PROCESSING_TOKEN`, data: orderProcessingToken}),
        resetOrderPending: (orderPending) => dispatch({ type: `SET_ORDER_PENDING`, data: null }),
    }
}

const CustomerInformation = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInformationComponent)  

export default CustomerInformation