import React from "react"
import styles from "./styles.module.css"
import productImage from "../../images/product_image.svg";
import edit from "../../images/edit_icon.svg";
import { decimal } from '../../common/numFormat';
import _Tooltip from "../Tooltip";
import { Additional_Services_Name, System_Type, Product_Type, AdditionalServicesOrderTooltip_Advisor, AdditionalServicesOrderTooltip_Pro } from "../../common/constants";
import { getAdditionalServicesTooltipText } from "../../common/gFunctions";

var dateFormat = require('dateformat');

class PlanInformation extends React.Component {

  render() {
    const { priceDetails, onEditClick, existingPlan, isUpgradePage=false } = this.props;
    const isUpgrade = priceDetails && (priceDetails.OrderType === 2 || priceDetails.OrderType === 3);
    const planInformation = priceDetails ? priceDetails.NewPlan : existingPlan;
    const systemType = planInformation.SystemType === System_Type.DESKTOP ? 'Desktop' : 'Cloud';
    const isCloud = planInformation.SystemType == System_Type.CLOUD;
    const systemTypeText = planInformation.IsFree ? systemType : `1 ${systemType} Base License`;
    const numAdditionalServices = (planInformation) ? planInformation.AdditionalServices.length : 0;
    var additonalServicesTooltipText;
    var additionalServicesTooltipName = 'additional-services-tooltip-' + (existingPlan ? 'existing' : 'new');
    const isFree = planInformation.ProductId == (isCloud ? 300 : 100);
    const isAdvisor = planInformation.ProductType == Product_Type.ADVISOR;
    const isPro = planInformation.ProductId == (isCloud ? 302 : 102);
    const isLegacyProduct = planInformation.IsLegacyProduct;
    
    if (planInformation) {
      if (numAdditionalServices > 0) {
        additonalServicesTooltipText = getAdditionalServicesTooltipText(planInformation.AdditionalServices, isAdvisor, isPro);
      } else {
        additonalServicesTooltipText = isAdvisor ? AdditionalServicesOrderTooltip_Advisor : AdditionalServicesOrderTooltip_Pro;
      }
    }

    return (
      <>
        <div className={[styles.form_container, styles.product_info_section, "text-left", "shadow-sm", "bg-white", "rounded", "mb-3", "product_info_section"].join(" ")}>
            <div className={["row","m-0"].join(" ")}>
                <div className={["col-md-2", "col-4", "p-md-0"].join(" ")}>
                    <img className="mt-2" src={productImage} alt="MProfit Product Logo"/>
                </div>
                <div className={["col-md-4", "col-8", "pl-md-4"].join(" ")}>
                    <label className="mb-1">{priceDetails ? (isUpgrade ? 'New Plan' : 'Product') : ('Current Plan')}</label>
                    <h5>{planInformation.ProductName}</h5>
                    {
                      isLegacyProduct &&
                      <span className={["d-block", "mpr_alert_success"].join(" ")}>{systemTypeText}</span>
                    }
                    {
                      isCloud &&
                      <span className={["d-block", "mpr_alert_success"].join(" ")}>{`${planInformation.NumCloudUsers} User Login${planInformation.NumCloudUsers > 1 ? 's' : ''}`}</span>
                    }
                    {
                      !planInformation.IsFree &&
                      <>
                        {
                          !isCloud &&
                          <label className={["d-block", "m-0"].join(" ")}>{planInformation.NumDesktopAddOns} Desktop Add-on{planInformation.NumDesktopAddOns!==1?'s':''}</label>
                        }
                        {
                          !isCloud &&
                          <label className={["d-block", "m-0"].join(" ")}>{planInformation.NumCloudAddOns + (isCloud ? '' : ' Cloud')} Add-on{planInformation.NumCloudAddOns!==1?'s':''}</label>
                        }
                        {
                          !isFree && isLegacyProduct &&
                          <label className="d-block">
                            {numAdditionalServices} Additional Pack{numAdditionalServices!==1?'s':''}
                            <span className="qcircle small-qcircle" id={additionalServicesTooltipName} data-toggle="tooltip" data-placement="right">?</span>
                            <_Tooltip text={additonalServicesTooltipText} header={"Additional packs in your plan:"} target={additionalServicesTooltipName}/>
                          </label>
                        }
                        {
                          !isLegacyProduct && planInformation.NumFamiliesAdditional > 0 &&
                          <label className="d-block">
                            {planInformation.NumFamiliesAdditional} Additional {planInformation.NumFamiliesAdditional!==1?'Families':'Family'}
                          </label>
                        }
                      </>
                    }
                </div>
                {
                  !planInformation.IsFree &&
                  <>
                    {
                      !isUpgradePage &&
                      <div className={["col-md-2", "col-4", "p-md-0"].join(" ")}>
                        <label className="mb-1">Expiry Date</label>
                        <h5>{dateFormat(new Date(planInformation.LicenseExpDt),'dd/mm/yy')}</h5>
                      </div>
                    }
                    {
                      priceDetails && !isUpgradePage &&
                      <div className={["col-md-3", "col-8"].join(" ")}>
                        <label className="mb-1">Amount Due (Rs.)</label>
                        <h5>{decimal(priceDetails.FinalAmount)}</h5>
                      </div>
                    }
                  </>
                }
                {
                  onEditClick &&
                  <div className={["col-md-1", "p-0", "pt-4", "text-right", "d-none", "d-md-block"].join(" ")}>
                    <button className={["btn", "btn-text", "p-0", 'btn_edit'].join(" ")} onClick={onEditClick}><img src={edit} alt="Edit Button" /></button>
                  </div>
                }
            </div>
        </div>
      </>
    )
  }
}

export default PlanInformation