import { PRICEINFO, CREATEPRELIMINARY, TOKEN, CREATEPENDING, PROCESSPAYUMONEY, ACCOUNTPRICEINFO, UPGRADEPRICEINFO, ACCOUNTCREATEPRELIMINARY, CHECKEMAIL, EDITPENDING, ADDDOWNLOAD, CLOUDSIGNUP, PRODUCTPRICING, AFFILIATEDISCOUNT, PROCESSRAZORPAY, CLOUDINQUIRY, REFERRALDISCOUNT } from "./API";
import { processResponse, parseBrowserStorageValueForNulls } from "../common/gFunctions";
import { isBrowser } from "./auth";
import { System_Type } from "../common/constants";

export const setUrlReferralCode = (code) => {
    if (code) {
        window.sessionStorage.setItem("mprofitUrlReferralCode", code)
    }
}

export const setUrlEmailAddr = (email) =>
  window.sessionStorage.setItem("mprofitUrlEmailAddr", email)

export const setUrlExtraFamilies = (showExtraFamilies) =>
  window.sessionStorage.setItem("mprofitUrlExtraFamilies", '' + showExtraFamilies)

export const getUrlReferralCode = () => {
    var code = isBrowser() && window.sessionStorage.getItem("mprofitUrlReferralCode")
    return parseBrowserStorageValueForNulls(code);
}

export const getUrlEmailAddr = () => {
    var email = isBrowser() && window.sessionStorage.getItem("mprofitUrlEmailAddr")
    return parseBrowserStorageValueForNulls(email);
}

export const getUrlExtraFamilies = () => {
    var email = isBrowser() && window.sessionStorage.getItem("mprofitUrlExtraFamilies")
    return parseBrowserStorageValueForNulls(email) === 'true';
}

export const priceInfo = (productId, systemType, numDesktopUsers, numCloudUsers, numFamiliesTotal, additionalServicesString, discountCode, customAUMLimit) => {
    var params = {
        productId,
        numDesktopUsers,
        numCloudUsers,
        numFamiliesTotal,
        discountCode,
        additionalServicesString,
        customAUMLimit
    };
    var url = createUrlQueryParamsEncoded(PRICEINFO,params);
    console.log(url);
    return fetch(url,{}).then(processResponse);
}

export const accountPriceInfo = (productId, systemType, numDesktopUsers, numCloudUsers, numFamiliesTotal, numFamiliesAdditional, additionalServicesString, discountCode, orderType, token, isApplyReferralPoints = false, customAUMLimit = null) => {
    var params = {
        productId,
        numDesktopUsers,
        numCloudUsers,
        numFamiliesTotal,
        numFamiliesAdditional,
        orderType,
        discountCode,
        additionalServicesString,
        isApplyReferralPoints,
        customAUMLimit
    };

    var url = createUrlQueryParams(ACCOUNTPRICEINFO,params);

    return fetch(url,{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }}).then(processResponse);
}

export const upgradePriceInfo = (productId, systemType, numDesktopUsers, numCloudUsers, numFamiliesTotal, additionalServicesString, discountCode, token, isApplyReferralPoints, customAUMLimit) => {
    var params = {
        productId,
        numDesktopUsers,
        numCloudUsers,
        numFamiliesTotal,
        discountCode,
        additionalServicesString,
        isApplyReferralPoints,
        customAUMLimit
    };
    var url = createUrlQueryParams(UPGRADEPRICEINFO,params);

    return fetch(url,{
        method: 'GET',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        }}).then(processResponse);
}

export const getProductPricing = (productId) => {
    var params = {
        productId,
    };
    var url = createUrlQueryParams(PRODUCTPRICING,params);

    return fetch(url,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }}).then(processResponse);
}

export const createPreliminary = (OrderNew, priceDetails, recaptchaToken) => {
    var newPlan = priceDetails.NewPlan;

    var params = {
        email: encodeURIComponent(OrderNew.CustomerDetails.EmailAddr),
        productId: newPlan.ProductId,
        numDesktopUsers: newPlan.NumDesktopUsers,
        numCloudUsers: newPlan.NumCloudUsers,
        numFamiliesAdditional: newPlan.NumFamiliesAdditional,
        discountCode: priceDetails.DiscountFields.DiscountCode,
        additionalServicesString: newPlan.AdditionalServicesString,
        recaptchaToken
    };

    console.log('CREATEPRELIM PARAMS',params,priceDetails);

    return fetch(createUrlQueryParams(CREATEPRELIMINARY,params), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(OrderNew)
    }).then(processResponse)
}

export const accountCreatePreliminary = (OrderNew, priceDetails, token, recaptchaToken) => {
    var newPlan = priceDetails.NewPlan;

    var params = {
        productId: newPlan.ProductId,
        numDesktopUsers: newPlan.NumDesktopUsers,
        numCloudUsers: newPlan.NumCloudUsers,
        numFamiliesAdditional: newPlan.NumFamiliesAdditional,
        orderType: priceDetails.OrderType,
        discountCode: priceDetails.DiscountFields.DiscountCode,
        additionalServicesString: newPlan.AdditionalServicesString,
        isApplyReferralPoints: priceDetails.IsApplyReferralPoints,
        customAUMLimit: newPlan.CustomAUMLimit,
        recaptchaToken
    };

    console.log('CREATEPRELIM PARAMS',params,priceDetails);

    return fetch(createUrlQueryParams(ACCOUNTCREATEPRELIMINARY,params), {
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(OrderNew)
    }).then(processResponse)
}

export const getOrderProcessingToken = (email, tempConfirmationKey) => {
    var loginDetails = {
        'grant_type':'password',
        'username': email,
        'password': tempConfirmationKey,
        'UserType': 2
    };

    var loginBody = [];
    for (var property in loginDetails) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(loginDetails[property]);
        loginBody.push(encodedKey + "=" + encodedValue);
    }
    loginBody = loginBody.join("&");

    return fetch(TOKEN, {
        method: 'POST',
        headers: {
          Accept: 'application/x-www-form-urlencoded',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: loginBody
    }).then(processResponse)
}

export const createPending = (token, tempConfirmationKey, paymentDetails=null) => {

    var params = {
        tempConfirmationKey
    };

    var body;
    if (paymentDetails) {
        body = JSON.stringify({PaymentDetails: paymentDetails});
    }

    return fetch(createUrlQueryParams(CREATEPENDING,params), {
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        },
        body: body
    }).then(processResponse)
}

export const processPayUMoney = (token,payUfields,orderRefNum) => {

    var params = {
        orderRefNum
    };

    const PayUGatewayFields = {
        "hash": payUfields.hash,
        "txnid": payUfields.txnid,
        "amount": payUfields.amount,
        "productinfo": payUfields.productinfo,
        "firstname": payUfields.firstname,
        "email": payUfields.email,
        "status": payUfields.status,
        "payuMoneyId": payUfields.payuMoneyId,
        "mode": payUfields.mode
    };

    return fetch(createUrlQueryParams(PROCESSPAYUMONEY,params), {
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(PayUGatewayFields)
    }).then(processResponse)
}

export const processRazorpaySuccess = (token,razorpayGatewayFields,orderRefNum) => {

    var params = {
        orderRefNum
    };

    return fetch(createUrlQueryParams(PROCESSRAZORPAY,params), {
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(razorpayGatewayFields)
    }).then(processResponse)
}

export const createUrlQueryParams = (url, params) => {
    url = url+'?';
    Object.keys(params).forEach(key => {
        if (params[key] !== null && params[key] !== undefined) {
            url = url+key+'='+params[key]+'&';
        }
    });
    url = url.slice(0,-1);
    return url;
}

export const createUrlQueryParamsEncoded = (url, params) => {
    url = url+'?';
    Object.keys(params).forEach(key => {
        if (params[key] !== null && params[key] !== undefined) {
            url = url+key+'='+encodeURIComponent(params[key])+'&';
        }
    });
    url = url.slice(0,-1);
    return url;
}

export const checkEmail = (email) => {
    var url = CHECKEMAIL+"?emailAddr="+encodeURIComponent(email);
    return fetch(url,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(processResponse);
}

export const editPending = (token, orderRefNum, paymentDetails=null) => {

    var params = {
        orderRefNum
    };

    var body;
    if (paymentDetails) {
        body = JSON.stringify(paymentDetails);
    }

    return fetch(createUrlQueryParams(EDITPENDING,params), {
        method: 'POST',
        headers: {
            'Authorization': 'bearer '+token,
            'Content-Type': 'application/json',
        },
        body: body
    }).then(processResponse)
}

export const addDownload = (body) => {
    body = JSON.stringify(body);


    return fetch(ADDDOWNLOAD, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body
    }).then(processResponse)
}

export const cloudSignUp = (body) => {
    body = JSON.stringify(body);


    return fetch(CLOUDSIGNUP, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body
    }).then(processResponse)
}

export const cloudInquiry = (body) => {
    body = JSON.stringify(body);


    return fetch(CLOUDINQUIRY, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body
    }).then(processResponse)
}

export const affiliateDiscountCheck = (affiliateUTM) => {
    var url = createUrlQueryParamsEncoded(AFFILIATEDISCOUNT,{affiliateUTM});

    return fetch(url, {}).then(processResponse)
}

export const referralDiscountCheck = (referralUTM) => {
    var url = createUrlQueryParamsEncoded(REFERRALDISCOUNT,{referralUTM});

    return fetch(url, {}).then(processResponse)
}