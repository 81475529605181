import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import FLayout from "../flayout";

// const PrivateRoute = ({ component: Component, location, hideLayout=false, ...rest}) => {
  
// }

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false
    }
  }
  componentDidMount() {
    if (!isLoggedIn() && this.props.location.pathname !== `/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/login`+this.props.location.search,{state: {redirectUrl: this.props.location.pathname + this.props.location.search, searchParams: this.props.location.search}})
      return null
    } else {
      this.setState({showComponent: true})
    }

    if (this.props.greyBackground) {
      document.body.classList.add('plan-grey-background');
    }
  }

  componentWillUnmount() {
    if (this.props.greyBackground) {
      document.body.classList.remove('plan-grey-background'); 
    }
  }

  render() {
    const {component: Component, location, hideLayout=false, showFooter=true, showSingleFooter=false, environment, seoFields, ...rest} = this.props;
    if (this.state.showComponent) {
      if (hideLayout) {
        return <Component {...rest} location={location}/>
      } else {
        return (
          <FLayout showFooter={showFooter} showSingleFooter={showSingleFooter} seoFields={{...seoFields,noIndex:true}} showAnnouncement={false}>
            <Component {...rest} location={location} environment={environment}/>
          </FLayout>
        )
      }
    } else {
      return <></>
    }
  }
}

export default PrivateRoute