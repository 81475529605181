import React from "react"
import CustomerInformation from "../../../components/customerInformation";
import PrivateRoute from "../../../components/privateRoute";

class CustomerInformationPage extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/order/details" component={CustomerInformation} location={this.props.location} showFooter={false} showSingleFooter={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "Buy Now",
}

export default CustomerInformationPage